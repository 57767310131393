import React from 'react';
import './SuccessPage.css'; // You'll need to create this CSS file

const SuccessPage = () => {
  return (
    <div className="success-page">
      <img src="/SHR-logo-black-bg.png" alt="Sutter Hill Ventures Logo" className="company-logo" />
      <h1>Application Submitted Successfully!</h1>
      <p>Thank you for your interest in joining Sutter Hill Research. We have received your application and will review it shortly.</p>
    </div>
  );
};

export default SuccessPage;