import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import './ApplicationForm.css';
import SuccessPage from './SuccessPage'; // Import the new SuccessPage component

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    resume: null,
    shortAnswer1: '',
    shortAnswer2: '',
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [charCounts, setCharCounts] = useState({
    shortAnswer1: 0,
    shortAnswer2: 0
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maxLength = 1000; // Increased character limit
    
    if (name === 'shortAnswer1' || name === 'shortAnswer2') {
      if (value.length <= maxLength) {
        setFormData({ ...formData, [name]: value });
        setCharCounts(prev => ({ ...prev, [name]: value.length }));
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'application/pdf') {
        setFormData({ ...formData, resume: file });
        setErrorMessage(null); // Clear any previous error messages
      } else {
        setFormData({ ...formData, resume: null });
        setErrorMessage("Please upload a PDF file.");
        e.target.value = ''; // Reset the file input
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');
    setErrorMessage(null);

    try {
      let resumeUrl = '';
      if (formData.resume) {
        const fileName = `${Date.now()}_${formData.resume.name}`;
        const storageRef = ref(storage, `resumes/${fileName}`);
        console.log('Attempting to upload file:', fileName);
        try {
          const snapshot = await uploadBytes(storageRef, formData.resume);
          console.log('Upload successful:', snapshot);
          
          try {
            resumeUrl = await getDownloadURL(storageRef);
            console.log('Download URL:', resumeUrl);
          } catch (downloadError) {
            console.error('Error getting download URL:', downloadError);
            // Use the storage path instead of the download URL
            resumeUrl = `resumes/${fileName}`;
          }
        } catch (uploadError) {
          console.error('Upload error:', uploadError);
          throw uploadError;
        }
      }

      console.log('Attempting to add document to Firestore');
      const docRef = await addDoc(collection(db, 'applications'), {
        name: formData.name,
        email: formData.email,
        resumeUrl,
        shortAnswer1: formData.shortAnswer1,
        shortAnswer2: formData.shortAnswer2,
        timestamp: new Date(),
        status: 'New'
      });
      console.log('Document added with ID:', docRef.id);

      setSubmitStatus('success');
      setShowSuccessPage(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      setErrorMessage(error.message || 'An error occurred while submitting the application.');
    }
  };

  if (showSuccessPage) {
    return <SuccessPage />;
  }

  return (
    <form onSubmit={handleSubmit} className="application-form">
      <img src="/SHR-logo-black-bg.png" alt="Sutter Hill Ventures Logo" className="company-logo" />
      
      <div className="position-description">
        <p>Sutter Hill Ventures is a venture capital firm that has financed technology companies and helped entrepreneurs build market-leading businesses since 1962. Through decades of experience, we have developed strong industry networks, considerable operating and investing expertise, and a deep understanding of the challenges that growing startups face.</p>
        <p>As an Analyst on the Sutter Hill Research team, you will work closely with our portfolio companies to understand their technical challenges, explore the frontiers of technology research, and attract the most impactful engineers and scientists to join the company-creation process.</p>
        <p>We are looking for adaptable and driven individuals who thrive in fast-paced environments, enjoy working with technology, and love solving open-ended problems. The ideal candidate will have strong communication and operational skills, exercise sound judgment, and be comfortable working both independently and as part of a team.</p>
        <p>This two-year full-time program is designed for recent bachelors and masters graduates.</p>
      </div>

      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="shortAnswer1">What is your favorite technology and why?</label>
        <textarea
          id="shortAnswer1"
          name="shortAnswer1"
          value={formData.shortAnswer1}
          onChange={handleInputChange}
          required
          maxLength={1000} // Added maxLength attribute
        />
        <div className="char-count">{charCounts.shortAnswer1}/1000</div>
      </div>

      <div className="form-group">
        <label htmlFor="shortAnswer2">Tell us about your proudest accomplishment.</label>
        <textarea
          id="shortAnswer2"
          name="shortAnswer2"
          value={formData.shortAnswer2}
          onChange={handleInputChange}
          required
          maxLength={1000} // Added maxLength attribute
        />
        <div className="char-count">{charCounts.shortAnswer2}/1000</div>
      </div>

      <div className="form-group">
        <label htmlFor="resume">Resume (PDF only):</label>
        <input
          type="file"
          id="resume"
          name="resume"
          onChange={handleFileChange}
          accept=".pdf"
          required
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      <button type="submit" disabled={submitStatus === 'submitting'}>
        {submitStatus === 'submitting' ? 'Submitting...' : 'Submit Application'}
      </button>

      {submitStatus === 'error' && (
        <p className="error-message">Error submitting application: {errorMessage}. Please try again.</p>
      )}
    </form>
  );
};

export default ApplicationForm;