import { initializeApp } from 'firebase/app';
import { getFirestore, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAsyP5HTGvDlK6MYGIHiXntBIoRMJdpicI",
    authDomain: "tortuga-b9991.firebaseapp.com",
    projectId: "tortuga-b9991",
    storageBucket: "tortuga-b9991.appspot.com",
    messagingSenderId: "376319484947",
    appId: "1:376319484947:web:eb9a1a0fea39e537020be6",
    measurementId: "G-B4JDEK0CF9"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { db, storage, auth, googleProvider, deleteDoc, doc, ref, deleteObject };