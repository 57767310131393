import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage'; // Add this import
import { db, storage, auth } from '../firebase'; // Import auth
import './ReviewApplication.css'; // We'll create this file for specific styles

const ReviewApplication = () => {
  const [application, setApplication] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchApplication = async () => {
      if (!auth.currentUser) {
        console.error('User not authenticated');
        return;
      }

      const docRef = doc(db, 'applications', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const appData = { id: docSnap.id, ...docSnap.data() };
        
        if (appData.resumeUrl && !appData.resumeUrl.startsWith('http')) {
          try {
            const storageRef = ref(storage, appData.resumeUrl);
            const downloadURL = await getDownloadURL(storageRef);
            appData.resumeUrl = downloadURL;
          } catch (error) {
            console.error('Error getting download URL:', error);
            // Keep the original resumeUrl if we can't get the download URL
          }
        }
        
        setApplication(appData);
      } else {
        console.log("No such document!");
      }
    };

    fetchApplication();
  }, [id]);

  const updateStatus = async (status) => {
    try {
      await updateDoc(doc(db, 'applications', id), { status });
      setApplication({ ...application, status });
      // Navigate back to the Admin page after updating the status
      history.push('/admin');
    } catch (error) {
      console.error("Error updating application status:", error);
      alert("Error updating application status. Please try again.");
    }
  };

  const getResumeFileName = (url) => {
    if (!url) return 'No file uploaded';
    if (url.startsWith('http')) {
      const parts = url.split('/');
      return decodeURIComponent(parts[parts.length - 1].split('?')[0]);
    } else {
      // If it's a storage path, just return the file name
      return url.split('/').pop();
    }
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    if (application.resumeUrl) {
      try {
        // If the URL is a storage path, get the download URL
        let downloadUrl = application.resumeUrl;
        if (!downloadUrl.startsWith('http')) {
          const storageRef = ref(storage, downloadUrl);
          downloadUrl = await getDownloadURL(storageRef);
        }

        // Open the download URL in a new tab
        window.open(downloadUrl, '_blank');
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Error downloading file. Please try again.');
      }
    }
  };

  if (!application) return <div>Loading...</div>;

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'inherit';
    }
  };

  return (
    <div className="review-application">
      <h1>
        Status: <span style={{ color: getStatusColor(application.status) }}>{application.status || 'New'}</span>
      </h1>
      <div className="form-group">
        <label>Name:</label>
        <input type="text" value={application.name} readOnly />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" value={application.email} readOnly />
      </div>
      <div className="form-group">
        <label>Favorite technology and why:</label>
        <textarea value={application.shortAnswer1} readOnly />
      </div>
      <div className="form-group">
        <label>Proudest accomplishment:</label>
        <textarea value={application.shortAnswer2} readOnly />
      </div>
      <div className="form-group resume-group">
        <label htmlFor="resume">Resume:</label>
        <div className="resume-container">
          {application && application.resumeUrl ? (
            <>
              <a 
                href="#"
                onClick={handleDownload}
                className="resume-button"
              >
                Download
              </a>
              <span className="file-name">{getResumeFileName(application.resumeUrl)}</span>
            </>
          ) : (
            <span>No resume uploaded or unable to retrieve resume</span>
          )}
        </div>
      </div>
      <div className="button-container">
        <button className="action-button accept-button" onClick={() => updateStatus('Accepted')}>Accept</button>
        <button className="action-button reject-button" onClick={() => updateStatus('Rejected')}>Reject</button>
      </div>
    </div>
  );
};

export default ReviewApplication;