import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';
import { Link } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    const q = query(collection(db, 'applications'));
    const querySnapshot = await getDocs(q);
    const apps = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    // Sort applications by status and then by timestamp (most recent first)
    const sortedApps = apps.sort((a, b) => {
      const statusOrder = { 'New': 0, 'Accepted': 1, 'Rejected': 2 };
      if (statusOrder[a.status || 'New'] !== statusOrder[b.status || 'New']) {
        return statusOrder[a.status || 'New'] - statusOrder[b.status || 'New'];
      }
      return b.timestamp?.toDate() - a.timestamp?.toDate();
    });

    setApplications(sortedApps);
  };

  const handleDelete = async (id, resumeUrl) => {
    if (window.confirm('Are you sure you want to delete this application?')) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'applications', id));

        // Delete the resume file from Storage
        if (resumeUrl) {
          const storageRef = ref(storage, resumeUrl);
          await deleteObject(storageRef);
        }

        // Update the state to remove the deleted application
        setApplications(applications.filter(app => app.id !== id));
      } catch (error) {
        console.error('Error deleting application:', error);
        alert('Failed to delete application. Please try again.');
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'inherit';
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="admin-panel">
      <h1>Applicants</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Submitted</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map(app => (
            <tr key={app.id}>
              <td>{app.name}</td>
              <td>{app.email}</td>
              <td>{formatTimestamp(app.timestamp)}</td>
              <td style={{ color: getStatusColor(app.status) }}>{app.status || 'New'}</td>
              <td>
                <div className="action-buttons">
                  <Link to={`/admin/review/${app.id}`} style={{ textDecoration: 'none' }}>
                    <button className="review-button">Review</button>
                  </Link>
                  <button className="delete-button" onClick={() => handleDelete(app.id, app.resumeUrl)}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;