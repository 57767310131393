import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import ApplicationForm from './components/ApplicationForm';
import Admin from './components/Admin';
import Login from './components/Login';
import ReviewApplication from './components/ReviewApplication';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAdmin(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={ApplicationForm} />
          <Route path="/login">
            {isAdmin ? <Redirect to="/admin" /> : <Login setIsAdmin={setIsAdmin} />}
          </Route>
          <Route exact path="/admin">
            {isAdmin ? <Admin /> : <Redirect to="/login" />}
          </Route>
          <Route path="/admin/review/:id">
            {isAdmin ? <ReviewApplication /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;